import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTModalCard = _resolveComponent("KTModalCard")!
  const _component_KTTwoFactorAuthModal = _resolveComponent("KTTwoFactorAuthModal")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_KTModalCard, {
      title: "Two-factor Authentication",
      description: "Click on the below buttons to launch <br/>two-factor authentication setup example.",
      image: "media/illustrations/sketchy-1/4.png",
      "button-text": "Enable Two-factor Authentication",
      "modal-id": "kt_modal_two_factor_authentication"
    }),
    _createVNode(_component_KTTwoFactorAuthModal)
  ], 64))
}